import PropTypes from "prop-types";
import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";

import { GradientBox } from "./GradientBox";
import { backgroundOptions } from "./backgroundOptions";



export function GradientBackground({ option, sx, ...rest }) {
    const useGradient = false;

    return (
        <Box
            as={motion.div}
            layout
            className="gradient-background"
            sx={{
                position: "absolute",
                top: "0px",
                display: "flex",
                height: "100%",
                width: "100%",
                bgGradient: backgroundOptions[ option ].gradient,
                zIndex: "hide",
                overflow: "hidden",
                pointerEvents: "none",
                ...sx,
            }}
            {...rest}
        >
            {useGradient && backgroundOptions[ option ].gradientElements.map(
                (box, index) => (
                    <GradientBox key={`box-${index}`} {...box} />
                )
            )}
        </Box>
    );
}

GradientBackground.propTypes = {
    option: PropTypes.number,
    sx: PropTypes.any,
};

GradientBackground.defaultProps = { option: 0 };
