import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";

export function LoadingSpinner({ label, ...rest }) {
    return (
        <Flex
            sx={{
                padding: 6,
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                color: "text",
                ...rest,
            }}
        >
            <Box>
                <Spinner />
                {label && (
                    <Text
                        sx={{
                            fontWeight: "bold",
                            marginBottom: 0,
                        }}
                    >
                        {label}
                    </Text>
                )}
            </Box>
        </Flex>
    );
}

LoadingSpinner.propTypes = {
    label: PropTypes.string,
};
