import { useRef } from "react";
import PropTypes from "prop-types";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Button,
} from "@chakra-ui/react";
import { LoadingSpinner } from "@/components/primitives/LoadingSpinner";
import { CloseIcon } from "@/components/icons";

/**
 * AlertDialog is using the Chakra Modal theme behind the scenes. More or less the same as Modal but with different usecases.
 * Read more at: https://chakra-ui.com/docs/components/alert-dialog
 */
export function ConfirmationModal({
    title,
    isOpen,
    onClose,
    onConfirm,
    children,
    isLoading,
    isDisabled,
    ...rest
}) {
    const cancelRef = useRef();

    return (
        <AlertDialog
            isCentered
            motionPreset="slideInBottom"
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isOpen={isOpen}
            variant="confirmation"
            {...rest}
        >
            <AlertDialogOverlay />

            <AlertDialogContent>
                <AlertDialogCloseButton ref={cancelRef}>
                    <CloseIcon color={null} sx={{ margin: 1 }} />
                </AlertDialogCloseButton>

                {title && <AlertDialogHeader mb={4}>{title}</AlertDialogHeader>}

                {children && <AlertDialogBody>{children}</AlertDialogBody>}

                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <AlertDialogFooter
                        sx={{
                            flexWrap: "wrap",
                            gap: 4,
                        }}
                    >
                        <Button
                            onClick={onClose}
                            variant="gray"
                            sx={{ flex: "1 1 0" }}
                        >
                            Fortryd
                        </Button>
                        <Button
                            onClick={onConfirm}
                            sx={{ flex: "1 1 0" }}
                            isDisabled={isDisabled}
                        >
                            Ja
                        </Button>
                    </AlertDialogFooter>
                )}
            </AlertDialogContent>
        </AlertDialog>
    );
}

ConfirmationModal.propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    children: PropTypes.node,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
    isOpen: false,
    isLoading: false,
    isDisabled: false,
};
