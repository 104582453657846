import PropTypes from "prop-types";
import { cloneElement } from "react";
import { ConfirmationModal } from "@/components/primitives/Modals";
import { signIn, getSession } from "next-auth/react";
import { useState } from "react";
import { clientConstants } from "@/services/client";
import { auth } from "@/services/client";
import { useQuery } from "@tanstack/react-query";

/**
 *
 * @param { node } children button component to attach click-event
 * @returns component with pre-confirmation modal before logging out
 */
export function SignInOutButton({ children, modalProps }) {
    const [isConfirmingLogout, setIsConfirmingLogout] = useState(false);

    const sessionQuery = useQuery({
        queryKey: ["session-status"],
        queryFn: () => getSession().then((session) => session), // returns boolean
        initialData: null,
    });

    /**
     * Handle click on the padlock icon to sign out/in user
     */
    const onButtonClick = () => {
        if (sessionQuery.data) {
            setIsConfirmingLogout(true);
        } else {
            signIn();

            // Lets child component know that a change in session has occoured
            if (children.props?.onClick) {
                children.props.onClick();
            }
        }
    };

    /**
     * Handle logout after user has approved on confirmation modal that
     * they want to be logged out
     */
    const handleLogoutConfirmation = () => {
        setIsConfirmingLogout(false);

        // Sign out & delete any relevant authenticated stuff
        localStorage.removeItem(clientConstants.IMPERSONATE_CPR_KEY_NAME); // if admin is impersonating - remove banner
        auth.signOut({ callbackUrl: "/" });

        // Lets child component know that a change in session has occoured
        if (children.props?.onClick) {
            children.props.onClick();
        }
    };

    return (
        <>
            {cloneElement(children, {
                onClick: onButtonClick,
            })}

            {/* Request user confirmation modal */}
            <ConfirmationModal
                title="Du er ved at logge af Mit Flexii"
                isOpen={isConfirmingLogout}
                onClose={() => setIsConfirmingLogout(false)}
                onConfirm={handleLogoutConfirmation}
                {...modalProps}
            />
        </>
    );
}

SignInOutButton.propTypes = {
    children: PropTypes.shape({
        props: PropTypes.shape({
            onClick: PropTypes.func,
        }),
    }).isRequired,
    modalProps: PropTypes.shape({
        blockScrollOnMount: PropTypes.bool,
    }),
};
