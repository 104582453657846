/**
 * This helper function is mapping Contentful background colors to theme colors
 *
 * Example usage:
 *  <Box sx={
 *      color: "white",
 *      ...contentfulBackground(background)
 *  }/>
 *
 * @param {string} background
 * @returns object
 *
 */


// To avoid color migration and name-clashing in Contentful we name our colors using
// --> https://www.color-name.com/hex/00AEFF <--
// Example: Darkest blue = #000055, suddenly becomes the 2nd darkest blue.
export function contentfulBackground(background) {
    switch(background) {
        default:
        case "light gray":
            return { backgroundColor: "gray.lightest" };
        case "dark gray":
            return { backgroundColor: "gray.dark" };
        case "white":
            return { backgroundColor: "white" };
        case "yellow":
            return { backgroundColor: "yellow" };
        case "purple":
            return { backgroundColor: "purple" };

        // Shades of Blue
        case "dark blue": // Legacy name, but still in use
            return { backgroundColor: "blue.darkest" };
        case "Cetacean Blue":
            return { backgroundColor: "blue.darkest" };
        case "Blue (Pigment)":
            return { backgroundColor: "blue.dark" };
        case "Blue Bolt":
            return { backgroundColor: "blue.vibrant" };
        case "Maya Blue":
            return { backgroundColor: "blue.light" };
        case "Light Cyan":
            return { backgroundColor: "blue.lighter" };
        case "Alice Blue":
            return { backgroundColor: "blue.lightest" };

        case "gradient":
            return {
                backgroundColor: "#7d0ecf",
                backgroundImage: "linear-gradient(45deg, #7d0ecf 0%, #00aeff 100%)",
            };
        case "none":
            return { background: "none" };
    }
};
