/**
 * SVG of an arrow point down with a circle around it
 * @returns svg
 */
export function ArrowDownInCircle () {
    return (
        <svg
            height={40}
            width={40}
            viewBox="0 0 123 123"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0,61.441c0-16.966,6.877-32.327,17.996-43.445C29.115,6.877,44.475,0,61.441,0c16.967,0,32.327,6.877,43.446,17.996 c11.119,11.119,17.996,26.479,17.996,43.445c0,16.966-6.877,32.326-17.996,43.445c-11.119,11.118-26.479,17.995-43.446,17.995 c-16.966,0-32.326-6.877-43.445-17.995C6.877,93.768,0,78.407,0,61.441L0,61.441z M42.166,51.505 c-1.784-1.735-4.637-1.695-6.373,0.088c-1.735,1.784-1.695,4.637,0.088,6.372l22.521,21.839l3.142-3.23l-3.146,3.244 c1.792,1.737,4.652,1.693,6.391-0.099c0.049-0.052,0.098-0.104,0.145-0.158l22.084-21.596c1.783-1.735,1.822-4.588,0.088-6.372 c-1.736-1.784-4.588-1.823-6.373-0.088L61.531,70.284L42.166,51.505L42.166,51.505z M24.386,24.386 C14.903,33.869,9.038,46.97,9.038,61.441c0,14.471,5.865,27.572,15.349,37.055c9.482,9.483,22.583,15.349,37.055,15.349 s27.573-5.865,37.055-15.349c9.484-9.482,15.35-22.584,15.35-37.055c0-14.472-5.865-27.573-15.35-37.056 C89.014,14.903,75.912,9.038,61.441,9.038S33.869,14.903,24.386,24.386L24.386,24.386z" />
        </svg>
    )
}
