import * as React from "react";

export function ExampleSimIcon(props) {
    return (
        <svg
            width={87}
            height={117}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#a)" fill="#4B51E4">
                <path d="M31.056 45.363a3.77 3.77 0 0 0 1.132 2.686 3.895 3.895 0 0 0 2.73 1.112h22.003c1.024 0 2.006-.4 2.73-1.112a3.767 3.767 0 0 0 1.13-2.686 3.767 3.767 0 0 0-1.13-2.685 3.893 3.893 0 0 0-2.73-1.113H34.92c-1.024 0-2.006.4-2.73 1.113a3.77 3.77 0 0 0-1.133 2.685ZM28.32 45.344c0-2.26-1.862-4.093-4.16-4.093-2.297 0-4.16 1.832-4.16 4.093 0 2.26 1.863 4.093 4.16 4.093 2.298 0 4.16-1.833 4.16-4.093ZM72 32.093C72 29.833 70.137 28 67.84 28c-2.298 0-4.16 1.832-4.16 4.093 0 2.26 1.862 4.093 4.16 4.093 2.297 0 4.16-1.832 4.16-4.093Z" />
            </g>
            <g clipPath="url(#b)" fill="#5B3DDE">
                <path d="M31.056 60.363a3.77 3.77 0 0 0 1.132 2.686 3.895 3.895 0 0 0 2.73 1.112h22.003c1.024 0 2.006-.4 2.73-1.112a3.767 3.767 0 0 0 1.13-2.686 3.767 3.767 0 0 0-1.13-2.685 3.893 3.893 0 0 0-2.73-1.113H34.92c-1.024 0-2.006.4-2.73 1.113a3.77 3.77 0 0 0-1.133 2.685ZM28.32 60.344c0-2.26-1.862-4.093-4.16-4.093-2.297 0-4.16 1.832-4.16 4.093 0 2.26 1.863 4.093 4.16 4.093 2.298 0 4.16-1.833 4.16-4.093ZM72 47.093C72 44.833 70.137 43 67.84 43c-2.298 0-4.16 1.833-4.16 4.093s1.862 4.093 4.16 4.093c2.297 0 4.16-1.832 4.16-4.093Z" />
            </g>
            <g clipPath="url(#c)" fill="#6234DB">
                <path d="M31.056 75.363a3.77 3.77 0 0 0 1.132 2.686 3.895 3.895 0 0 0 2.73 1.112h22.003c1.024 0 2.006-.4 2.73-1.112a3.767 3.767 0 0 0 1.13-2.686 3.767 3.767 0 0 0-1.13-2.685 3.893 3.893 0 0 0-2.73-1.113H34.92c-1.024 0-2.006.4-2.73 1.112a3.77 3.77 0 0 0-1.133 2.686ZM28.32 75.344c0-2.26-1.862-4.093-4.16-4.093-2.297 0-4.16 1.832-4.16 4.093 0 2.26 1.863 4.093 4.16 4.093 2.298 0 4.16-1.833 4.16-4.093ZM72 62.093C72 59.833 70.137 58 67.84 58c-2.298 0-4.16 1.833-4.16 4.093s1.862 4.093 4.16 4.093c2.297 0 4.16-1.832 4.16-4.093Z" />
            </g>
            <g clipPath="url(#d)" fill="#6A2AD8">
                <path d="M31.056 90.363a3.77 3.77 0 0 0 1.132 2.686 3.895 3.895 0 0 0 2.73 1.112h22.003c1.024 0 2.006-.4 2.73-1.112a3.767 3.767 0 0 0 1.13-2.686 3.767 3.767 0 0 0-1.13-2.685 3.893 3.893 0 0 0-2.73-1.113H34.92c-1.024 0-2.006.4-2.73 1.112a3.77 3.77 0 0 0-1.133 2.686ZM28.32 90.344c0-2.26-1.862-4.093-4.16-4.093-2.297 0-4.16 1.832-4.16 4.093 0 2.26 1.863 4.093 4.16 4.093 2.298 0 4.16-1.833 4.16-4.093ZM72 77.093C72 74.833 70.137 73 67.84 73c-2.298 0-4.16 1.832-4.16 4.093 0 2.26 1.862 4.093 4.16 4.093 2.297 0 4.16-1.832 4.16-4.093Z" />
            </g>
            <path
                d="M3 17C3 9.268 9.268 3 17 3h27.797a14 14 0 0 1 10.028 4.23L69.018 21.8l11.463 12.943A14 14 0 0 1 84 44.025V100c0 7.732-6.268 14-14 14H17c-7.732 0-14-6.268-14-14V17Z"
                stroke="url(#e)"
                strokeWidth={6}
            />
            <g clipPath="url(#f)" fill="#4B51E4">
                <path d="M31.056 45.363a3.77 3.77 0 0 0 1.132 2.686 3.895 3.895 0 0 0 2.73 1.112h22.003c1.024 0 2.006-.4 2.73-1.112a3.767 3.767 0 0 0 1.13-2.686 3.767 3.767 0 0 0-1.13-2.685 3.893 3.893 0 0 0-2.73-1.113H34.92c-1.024 0-2.006.4-2.73 1.113a3.77 3.77 0 0 0-1.133 2.685ZM28.32 45.344c0-2.26-1.862-4.093-4.16-4.093-2.297 0-4.16 1.832-4.16 4.093 0 2.26 1.863 4.093 4.16 4.093 2.298 0 4.16-1.833 4.16-4.093ZM72 32.093C72 29.833 70.137 28 67.84 28c-2.298 0-4.16 1.832-4.16 4.093 0 2.26 1.862 4.093 4.16 4.093 2.297 0 4.16-1.832 4.16-4.093Z" />
            </g>
            <g clipPath="url(#g)" fill="#5B3DDE">
                <path d="M31.056 60.363a3.77 3.77 0 0 0 1.132 2.686 3.895 3.895 0 0 0 2.73 1.112h22.003c1.024 0 2.006-.4 2.73-1.112a3.767 3.767 0 0 0 1.13-2.686 3.767 3.767 0 0 0-1.13-2.685 3.893 3.893 0 0 0-2.73-1.113H34.92c-1.024 0-2.006.4-2.73 1.113a3.77 3.77 0 0 0-1.133 2.685ZM28.32 60.344c0-2.26-1.862-4.093-4.16-4.093-2.297 0-4.16 1.832-4.16 4.093 0 2.26 1.863 4.093 4.16 4.093 2.298 0 4.16-1.833 4.16-4.093ZM72 47.093C72 44.833 70.137 43 67.84 43c-2.298 0-4.16 1.833-4.16 4.093s1.862 4.093 4.16 4.093c2.297 0 4.16-1.832 4.16-4.093Z" />
            </g>
            <g clipPath="url(#h)" fill="#6234DB">
                <path d="M31.056 75.363a3.77 3.77 0 0 0 1.132 2.686 3.895 3.895 0 0 0 2.73 1.112h22.003c1.024 0 2.006-.4 2.73-1.112a3.767 3.767 0 0 0 1.13-2.686 3.767 3.767 0 0 0-1.13-2.685 3.893 3.893 0 0 0-2.73-1.113H34.92c-1.024 0-2.006.4-2.73 1.112a3.77 3.77 0 0 0-1.133 2.686ZM28.32 75.344c0-2.26-1.862-4.093-4.16-4.093-2.297 0-4.16 1.832-4.16 4.093 0 2.26 1.863 4.093 4.16 4.093 2.298 0 4.16-1.833 4.16-4.093ZM72 62.093C72 59.833 70.137 58 67.84 58c-2.298 0-4.16 1.833-4.16 4.093s1.862 4.093 4.16 4.093c2.297 0 4.16-1.832 4.16-4.093Z" />
            </g>
            <g clipPath="url(#i)" fill="#6A2AD8">
                <path d="M31.056 90.363a3.77 3.77 0 0 0 1.132 2.686 3.895 3.895 0 0 0 2.73 1.112h22.003c1.024 0 2.006-.4 2.73-1.112a3.767 3.767 0 0 0 1.13-2.686 3.767 3.767 0 0 0-1.13-2.685 3.893 3.893 0 0 0-2.73-1.113H34.92c-1.024 0-2.006.4-2.73 1.112a3.77 3.77 0 0 0-1.133 2.686ZM28.32 90.344c0-2.26-1.862-4.093-4.16-4.093-2.297 0-4.16 1.832-4.16 4.093 0 2.26 1.863 4.093 4.16 4.093 2.298 0 4.16-1.833 4.16-4.093ZM72 77.093C72 74.833 70.137 73 67.84 73c-2.298 0-4.16 1.832-4.16 4.093 0 2.26 1.862 4.093 4.16 4.093 2.297 0 4.16-1.832 4.16-4.093Z" />
            </g>
            <path
                d="M3 17C3 9.268 9.268 3 17 3h27.797a14 14 0 0 1 10.028 4.23L69.018 21.8l11.463 12.943A14 14 0 0 1 84 44.025V100c0 7.732-6.268 14-14 14H17c-7.732 0-14-6.268-14-14V17Z"
                stroke="url(#j)"
                strokeWidth={6}
            />
            <defs>
                <clipPath id="a">
                    <path
                        fill="#fff"
                        transform="rotate(-90 35.5 15.5)"
                        d="M0 0h15v52H0z"
                    />
                </clipPath>
                <clipPath id="b">
                    <path
                        fill="#fff"
                        transform="rotate(-90 43 23)"
                        d="M0 0h15v52H0z"
                    />
                </clipPath>
                <clipPath id="c">
                    <path
                        fill="#fff"
                        transform="rotate(-90 50.5 30.5)"
                        d="M0 0h15v52H0z"
                    />
                </clipPath>
                <clipPath id="d">
                    <path
                        fill="#fff"
                        transform="rotate(-90 58 38)"
                        d="M0 0h15v52H0z"
                    />
                </clipPath>
                <clipPath id="f">
                    <path
                        fill="#fff"
                        transform="rotate(-90 35.5 15.5)"
                        d="M0 0h15v52H0z"
                    />
                </clipPath>
                <clipPath id="g">
                    <path
                        fill="#fff"
                        transform="rotate(-90 43 23)"
                        d="M0 0h15v52H0z"
                    />
                </clipPath>
                <clipPath id="h">
                    <path
                        fill="#fff"
                        transform="rotate(-90 50.5 30.5)"
                        d="M0 0h15v52H0z"
                    />
                </clipPath>
                <clipPath id="i">
                    <path
                        fill="#fff"
                        transform="rotate(-90 58 38)"
                        d="M0 0h15v52H0z"
                    />
                </clipPath>
                <linearGradient
                    id="e"
                    x1={-16}
                    y1={129}
                    x2={100.5}
                    y2={12.5}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7E0FD0" />
                    <stop offset={1} stopColor="#0E9DFA" />
                </linearGradient>
                <linearGradient
                    id="j"
                    x1={-16}
                    y1={129}
                    x2={100.5}
                    y2={12.5}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7E0FD0" />
                    <stop offset={1} stopColor="#0E9DFA" />
                </linearGradient>
            </defs>
        </svg>
    );
};
