import { chakra } from "@chakra-ui/react";

function CalendarIconComp(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            viewBox="0 0 119.92 122.88"
            height={15}
            width={15}
            {...props}
        >
            <title>{"schedule-calendar"}</title>
            <path d="M108.68 122.88H11.24A11.28 11.28 0 0 1 0 111.64V22.55a11.28 11.28 0 0 1 11.24-11.24h10.37v13.83a12.35 12.35 0 0 0 4.67 9.61 14.55 14.55 0 0 0 18.31 0 12.35 12.35 0 0 0 4.67-9.61V11.31H70.2v13.83a12.35 12.35 0 0 0 4.67 9.61 14.55 14.55 0 0 0 18.31 0 12.35 12.35 0 0 0 4.67-9.61V11.31h10.83a11.3 11.3 0 0 1 11.24 11.24v89.09a11.27 11.27 0 0 1-11.24 11.24Zm-25.1-66.11h16.1a2.07 2.07 0 0 1 2.06 2v13.4a2.07 2.07 0 0 1-2.06 2h-16.1a2.06 2.06 0 0 1-2-2V58.82a2.05 2.05 0 0 1 2-2Zm-31.51 0h16.11a2.06 2.06 0 0 1 2 2v13.4a2.07 2.07 0 0 1-2 2H52.07a2.06 2.06 0 0 1-2-2V58.82a2.06 2.06 0 0 1 2-2Zm-31.84 0h16.11a2.06 2.06 0 0 1 2 2v13.4a2.07 2.07 0 0 1-2 2H20.23a2.06 2.06 0 0 1-2.05-2V58.82a2.05 2.05 0 0 1 2.05-2Zm63.35 28.49h16.1a2.07 2.07 0 0 1 2.06 2v13.4a2.06 2.06 0 0 1-2.06 2.05h-16.1a2.06 2.06 0 0 1-2-2.05V87.31a2.06 2.06 0 0 1 2-2Zm-31.51 0h16.11a2.06 2.06 0 0 1 2 2v13.4a2.06 2.06 0 0 1-2 2.05H52.07a2.06 2.06 0 0 1-2-2.05V87.31a2.07 2.07 0 0 1 2-2Zm-31.84 0h16.11a2.06 2.06 0 0 1 2 2v13.4a2.06 2.06 0 0 1-2 2.05H20.23a2.06 2.06 0 0 1-2.05-2.05V87.31a2.06 2.06 0 0 1 2.05-2ZM78.6 4.45C78.6 2 81 0 84 0s5.43 2 5.43 4.45v20.69c0 2.46-2.42 4.45-5.43 4.45s-5.42-2-5.42-4.45V4.45Zm-48.6 0C30 2 32.44 0 35.44 0s5.42 2 5.42 4.45v20.69c0 2.46-2.42 4.45-5.42 4.45S30 27.6 30 25.14V4.45ZM3.6 43.86v66.58a8.87 8.87 0 0 0 8.84 8.84h95a8.87 8.87 0 0 0 8.85-8.84V43.86Z" />
        </svg>
    );
}

export const CalendarIcon = chakra(CalendarIconComp);