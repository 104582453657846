
import PropTypes from "prop-types"
export function ArrowLeftIcon ({ fill = null, ...rest }) {
    return (
        <svg
            fill="none"
            height={13}
            width={8}
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M7.53 12.52a1.642 1.642 0 0 0 .471-1.16 1.67 1.67 0 0 0-.47-1.159L3.909 6.5l3.622-3.702A1.64 1.64 0 0 0 8 1.638 1.67 1.67 0 0 0 7.53.48 1.6 1.6 0 0 0 6.398 0a1.571 1.571 0 0 0-1.134.48L.806 5.037c-.123.072-.237.16-.337.262-.3.324-.468.754-.468 1.2 0 .447.168.876.468 1.2.1.103.214.191.337.264l4.458 4.557c.3.307.709.48 1.134.48.425 0 .832-.173 1.133-.48Z"
                fill={fill ?? "currentColor"}
            />
        </svg>
    )
}

ArrowLeftIcon.propTypes = { fill: PropTypes.string }
