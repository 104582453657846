import { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import NextLink from "next/link";

import { Box, Link, useBreakpointValue } from "@chakra-ui/react";
import { motion, useAnimationControls } from "framer-motion";
import { useRouter } from "next/router";

const indicatorVariants = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: () => ({
        pathLength: 1,
        opacity: 1,
        transition: {
            pathLength: { type: "spring", duration: 0.5, bounce: 0 },
            opacity: { duration: 0.01 },
        },
    }),
};

/**
 *
 * @param {*} additionalActiveUrls Array of additional URLs that will mark the the menu item as active. Example: The "/login" path will also mark menuitem "Mit-Flexii" as active
 */
export function NavigationItem({
    url,
    children,
    sx,
    additionalActiveUrls,
    ...rest
}) {
    const router = useRouter();
    const svgRef = useRef();
    const isCurrentPage = router.asPath === url || additionalActiveUrls?.includes(router.pathname);

    const animControls = useAnimationControls(indicatorVariants);
    const isMobile = useBreakpointValue(
        { base: true, md: false },
    );
    const [strokeWidth, setStrokeWidth] = useState(2);

    const calculateStrokeWidth = useCallback(() => {
        const elementWidth = svgRef.current?.getBoundingClientRect().width;
        const multipliers = { // Defaultvalues for a svg path
            width: 150, // standard width of a path
            stroke: 2, // standard stroke width of a path
        };

        return elementWidth
            ? (multipliers.width / elementWidth) * multipliers.stroke
            : multipliers.stroke
    }, []);

    // Recalculate strokeWidth on layout change (isMobile)
    useEffect(() => {
        setStrokeWidth(calculateStrokeWidth);
    }, [calculateStrokeWidth, setStrokeWidth, isMobile]);

    // Listen for route changes
    useEffect(() => {
        animControls.set(isCurrentPage ? "visible" : "hidden");
    }, [animControls, isCurrentPage, router.asPath]);

    return (
        <Link
            as={NextLink}
            href={url}
            sx={{
                position: "relative",
                display: "flex",
                paddingX: {
                    xl: 3,
                },
                height: {
                    base: "auto",
                    xl: "100%",
                },
                alignItems: "center",
                justifyContent: "center",
                color: "inherit",
                fontSize: {
                    base: "h4",
                    xl: "base",
                },
                fontWeight: "black",
                textDecoration: "none",
                transition: "0.2s ease",
                ...sx,
            }}
            {...rest}
        >
            <Box
                sx={{ position: "relative" }}
                as={motion.div}
                animate={animControls}
                {...(!isCurrentPage && {
                    onHoverStart: () => animControls.start("visible"),
                    onHoverEnd: () => animControls.start("hidden"),
                })}
            >
                {children}
                <Box
                    className="menuitem-underline"
                    sx={{
                        position: "absolute",
                        top: "90%",
                        left: 0,
                        right: 0,
                    }}
                >
                    <svg
                        ref={svgRef}
                        viewBox="0 0 100 12"
                        width="100%"
                        height="100%"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <motion.path
                            variants={indicatorVariants}
                            d={`
                                M   ${strokeWidth} 6
                                C   7.5
                                    4
                                    30.939
                                    0
                                    ${100 - strokeWidth}
                                    ${strokeWidth}
                            `}
                            stroke="currentColor"
                            strokeWidth={strokeWidth}
                            strokeLinecap="round"
                            fill="transparent"
                        />
                    </svg>
                </Box>
            </Box>
        </Link>
    );
}

NavigationItem.defaultProps = {
    additionalActiveUrls: [],
}

NavigationItem.propTypes = {
    children: PropTypes.node.isRequired,
    url: PropTypes.string.isRequired,
    additionalActiveUrls: PropTypes.arrayOf(PropTypes.string),
};
