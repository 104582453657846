import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Flex, useBreakpointValue } from "@chakra-ui/react";
import { useRouter } from "next/router";

import { Logo } from "@/components/primitives/Logo";
import { Section } from "@/components/layouts/Section";
import { NavigationItem } from "./NavigationItem";
import { MobileNavigation } from "./MobileNavigation";
import { DesktopNavigation } from "./DesktopNavigation";
import { mitFlexiiNavItems } from "./MitFlexii/Sidebar";

// Specify height as a variable to use it as a guide for other elements (60px + padding * 2)
export const navigationHeight = {
    base: `calc(60px + 16px * 2)`,
    md: `calc(60px + 28px * 2)`,
};

export function Navigation(props) {
    const [navItems, setNavItems] = useState([]); // This one holds all navigation items for both mobile & desktop
    const router = useRouter();

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const isMobile = useBreakpointValue({
        base: true,
        xl: false,
    });

    // SSG - Closing burgermenu on routeChange
    useEffect(() => {
        if (!isMobile) return undefined;
        setIsMobileMenuOpen(false);
    }, [isMobile, router.query.contentfulSlug]);

    // Update menu navItems when props change
    useEffect(() => {
        setNavItems(props.fields.navigationItems);
    }, [props.fields.navigationItems]);

    // Prevent bodyscroll when mobile menu open
    useEffect(() => {
        if (isMobileMenuOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = null;
        }

        return () => {
            document.body.style.overflow = null;
        };
    }, [isMobileMenuOpen]);

    /**
     * Render navigation Items based on the current path
     * "/mitflexii" -> render MitFlexii Nav Items for mobile only,
     * instead of Menu items loaded from Contentul
     */
    const renderNavigationItems = useCallback(
        ({ isMobile = false } = {}) => {
            const rootPath = router.pathname.split("/")[1];
            return (
                <>
                    {isMobile && rootPath === "mitflexii"
                        ? mitFlexiiNavItems.map((navItem) => (
                              <NavigationItem
                                  key={navItem.url}
                                  url={navItem.url}
                              >
                                  {navItem.title}
                              </NavigationItem>
                          ))
                        : navItems.map((navItem) => (
                              <NavigationItem
                                  key={navItem.sys.id}
                                  url={navItem.fields.linkUrl}
                              >
                                  {navItem.fields.linkLabel}
                              </NavigationItem>
                          ))}
                    {isMobile && rootPath === "mitflexii" ? null : (
                        <NavigationItem
                            url="/mitflexii"
                            additionalActiveUrls={["/login"]}
                        >
                            Mit Flexii
                        </NavigationItem>
                    )}
                </>
            );
        },
        [navItems, router.pathname]
    );

    return (
        <Section
            as="nav"
            className="navigation"
            sx={{
                position: isMobileMenuOpen ? "fixed" : "absolute",
                top: 0,
                left: 0,
                right: 0,
                zIndex: "overlay",
                display: "flex",
                paddingY: {
                    base: 4,
                    lg: 7,
                },
                height: isMobileMenuOpen ? "full" : navigationHeight,
                color: "white",
                overflowX: "hidden",
                overflowY: "auto",
            }}
        >
            <Section.Content>
                <Flex
                    sx={{
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                    }}
                >
                    <Logo sx={{ position: "relative" }} />

                    {isMobile ? (
                        <MobileNavigation
                            isMobileMenuOpen={isMobileMenuOpen}
                            setIsMobileMenuOpen={setIsMobileMenuOpen}
                        >
                            {renderNavigationItems({ isMobile: true })}
                        </MobileNavigation>
                    ) : (
                        <DesktopNavigation>
                            {renderNavigationItems()}
                        </DesktopNavigation>
                    )}
                </Flex>
            </Section.Content>
        </Section>
    );
}

Navigation.propTypes = {
    fields: PropTypes.shape({
        navigationItems: PropTypes.array,
    }),
};
