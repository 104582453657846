const backgroundOptions = [
    {
        gradient: "linear-gradient(46.8deg, #630ECF 16.23%, #00AEFF 62.89%)",
        gradietOld: "linear-gradient(57.8deg, #630ECF 50.23%, #00AEFF 78.89%)",
        gradientElements: [
            {
                aspectRatio: "77%",
                sxProps: {
                    top: ["32%", null, null, "2%"],
                    left: "5%",
                    width: "55%",
                    background: "rgb(255, 173, 15)",
                    opacity: 0.4,
                    filter: "blur(150px)",
                    transform: "rotate(11.07deg)",
                },
                animateProps: {
                    scale: 1.4,
                    rotate: "20deg",
                    opacity: 0.5,
                },
                transitionProps: {
                    duration: 4,
                    ease: "easeInOut",
                    repeat: Number.POSITIVE_INFINITY,
                    repeatType: "reverse",
                },
            },
            {
                aspectRatio: "69%",
                sxProps: {
                    top: "-10%",
                    right: "-14%",
                    width: "51%",
                    background: "rgb(255, 173, 15)",
                    opacity: 0.5,
                    filter: "blur(200px)",
                    transform: "rotate(11.07deg)",
                },
                animateProps: {
                    scale: 1.2,
                    translateX: "30%",
                    translateY: "30%",
                },
                transitionProps: {
                    duration: 10,
                    ease: "easeInOut",
                    repeat: Number.POSITIVE_INFINITY,
                    repeatType: "reverse",
                },
            },
        ],
    },
];

export { backgroundOptions };
