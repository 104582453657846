import { useSession } from "next-auth/react";
import { Stack, Flex, Link, Text, HStack, Box } from "@chakra-ui/react";
import NextLink from "next/link";

import { Logo } from "../../primitives/Logo";
import { colors } from "../../../theme/foundations/colors";
import { Section } from "../../layouts/Section";
import { SignInOutButton } from "@/components/primitives/SignInOutButton";
import Image from "next/image";

export function Footer(contentfulData) {
    const { data: session } = useSession();
    const { fields } = contentfulData;

    return (
        <Section
            as="footer"
            role="contentinfo"
            sx={{
                minHeight: "50px",
                background: "white",
                marginTop: "auto",
            }}
        >
            <Section.Content>
                <Stack
                    spacing="8"
                    sx={{
                        paddingY: [12, 16],
                        justifyContent: "space-between",
                        alignItems: "start",
                        flexDirection: {
                            base: "column",
                            "2xl": "row",
                        },
                    }}
                >
                    <Stack align="start" sx={{ marginBottom: [8, null, 0] }}>
                        <Logo color={colors.text} />
                        <Text whiteSpace="pre" fontSize="xs" fontWeight="bold">
                            {fields.companyInfo}
                        </Text>
                        {fields.links ? (
                            <HStack pt={1}>
                                {fields.links.map(columnItem => {
                                    if (!columnItem.fields) return null;
                                    const { image, button } = columnItem.fields;
                                    const { linkUrl } = button?.fields;

                                    const SocialImage = () => (
                                        <Image
                                            src={`https:${image.fields.file.url}`}
                                            width={35}
                                            height={35}
                                            alt={image.fields.description || ""}
                                        />
                                    );
                                    return (
                                        <Box key={columnItem.sys.id}>
                                            {linkUrl ? (
                                                <>
                                                    <Link
                                                        href={linkUrl}
                                                        as={NextLink}
                                                        variant="none"
                                                        isExternal
                                                        target="_blank"
                                                    >
                                                        <SocialImage />
                                                    </Link>
                                                </>
                                            ) : (
                                                <SocialImage />
                                            )}
                                        </Box>
                                    );
                                })}
                            </HStack>
                        ) : null}
                    </Stack>
                    <Flex
                        sx={{
                            gap: 12,
                            width: "100%",
                            overflowX: "hidden",
                            justifyContent: {
                                base: "flex-start",
                                "2xl": "flex-end",
                            },
                            flexDirection: {
                                base: "column",
                                md: "row",
                            },
                            marginTop: {
                                base: 8,
                                "2xl": "0px !important",
                            },
                        }}
                    >
                        {fields.sections.map((sectionItem) => {
                            if (!sectionItem.fields) return null;

                            const navigationTitleItem =
                                sectionItem.fields.firstLevelItem;
                            const navigationItems =
                                sectionItem.fields.secondLevelItems;

                            return (
                                <Stack key={sectionItem.sys.id} spacing={2}>
                                    <Link
                                        href={navigationTitleItem.fields.linkUrl}
                                        as={NextLink}
                                        variant="footer"
                                        sx={{
                                            fontSize: "h4",
                                            fontWeight: "black",
                                        }}
                                    >
                                        {
                                            navigationTitleItem.fields
                                                .linkLabel
                                        }
                                    </Link>

                                    <Stack shouldWrapChildren spacing={0}>
                                        {navigationItems.map((navItem) => {
                                            if (!navItem.fields) return null;
                                            return (
                                                <Link
                                                    key={navItem.sys.id}
                                                    href={navItem.fields.linkUrl}
                                                    as={NextLink}
                                                    variant="footer"
                                                >
                                                    {
                                                        navItem.fields
                                                            .linkLabel
                                                    }
                                                </Link>
                                            );
                                        })}
                                    </Stack>
                                </Stack>
                            );
                        })}

                        <Stack spacing={2}>
                            <Link
                                href={"/mitflexii"}
                                as={NextLink}
                                variant="footer"
                                sx={{
                                    fontSize: "h4",
                                    fontWeight: "black",
                                }}
                            >
                                Mit Flexii
                            </Link>

                            <Stack shouldWrapChildren spacing={0}>
                                <SignInOutButton>
                                    <Link as="button" variant="footer">
                                        {session ? "Log ud" : "Log ind"}
                                    </Link>
                                </SignInOutButton>
                            </Stack>
                        </Stack>
                    </Flex>
                </Stack>
            </Section.Content>
        </Section>
    );
}
