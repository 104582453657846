/**
 * This helper function is mapping Contentful text colors to theme colors
 *
 * Example usage:
 *  <Box sx={
 *      fontSize: "12px",
 *      ...contentfulTextColor(color)
 *  }/>
 *
 * @param {string} color
 * @returns object
 */

export function contentfulTextColor(color) {
    switch(color) {
        default:
        case "dark blue":
            return { color: "text" };
        case "white":
            return { color: "white" };
    }
};
