import { chakra } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { motion, isValidMotionProp } from "framer-motion"

function GradientBox ({
    sxProps,
    aspectRatio,
    animateProps,
    transitionProps,
}) {
    const ChakraBox = chakra(motion.div, { shouldForwardProp: isValidMotionProp })

    return (
        <ChakraBox
            animate={animateProps}
            transition={transitionProps}
            sx={{
                position    : "absolute",
                borderRadius: "50%",
                _after      : {
                    content   : "''",
                    display   : "block",
                    paddingTop: aspectRatio,
                },
                willChange: "transform, opacity",
                ...sxProps,
            }}
            transformTemplate={({
                scale = 1,
                rotate = 0,
                translateX = 0,
                translateY = 0,
                translateZ = 0,
            }) => `translate3D(${translateX},${translateY},${translateZ}) scale(${scale}) rotate(${rotate})`}
        />
    )
}

GradientBox.propTypes = {
    sxProps        : PropTypes.object,
    aspectRatio    : PropTypes.string,
    animateProps   : PropTypes.object,
    transitionProps: PropTypes.object,
}

GradientBox.defaultProps = { aspectRatio: "100%" }

export {
    GradientBox,
}
