import PropTypes from "prop-types"
import { Box } from "@chakra-ui/react";

export function SectionDivider({
    position,
    shape,
    fillColor,
    flipY,
    flipX,
}) {
    const sectionDividerOptions = [(
        <svg key="random-2ggs4" xmlns="http://www.w3.org/2000/svg" viewBox="0 -3.4641 100 3.464">
            <path d="M 0 0 L 100 0 C 80 -6 25 -3 0 0 Z" fill="#000000"/>
        </svg>
    ), (
        <svg key="random-d2002" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 5">
            <path d="M 0 0 l 100 0 L 100 5 C 75 1 25 1 0 5 Z" fill="#000000"/>
        </svg>
    ), (
        <svg key="random-23fe3214" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 14">
            <path d="M 0 0 l 100 0 L 100 14 C 68 10 39 6 0 13 Z" fill="#000000"/>
        </svg>
    ), (
        <svg key="random-35gdl120" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 10">
            <path d="M 0 0 l 100 0 L 100 10 C 84 0 37 0 0 5 Z" fill="#000000"/>
        </svg>
    ), (
        <svg key="random-2jjj4" className="shape2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#a2d9ff" d="M0,256L80,224C160,192,320,128,480,133.3C640,139,800,213,960,224C1120,235,1280,181,1360,154.7L1440,128L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg>
    )];

    const selectedWave = sectionDividerOptions[shape];

    // Convert position value from CMS to CSS value.
    // -- Note: Translate hack added to prevent pixel density issues on some screens/-sizes
    const positionValues = {
        "top-inside": { top: "0%", transform: "translateY(-1px)" },
        "top-outside": { bottom: "100%", transform: "translateY(1px)" },
        "bottom-inside": { bottom: "0%", transform: "translateY(1px)" },
        "bottom-outside": { top: "100%", transform: "translateY(-1px)" },
    };

    return (
        <Box
            className={`section-divider`}
            data-position={position}
            data-shape-index={shape}
            sx={{
                ...(positionValues[position]),
                position: "absolute",
                width: "full",
                zIndex: 1,
                pointerEvents: "none",
                "svg path": {
                    fill: fillColor || "",
                }
            }}
        >
            <Box sx={{
                transform: `${flipY ? "rotateX(180deg)" : ""} ${flipX ? "rotateY(180deg)" : ""}`
            }}>
                {selectedWave}
            </Box>
        </Box>
    );
}

SectionDivider.propTypes = {
    position: PropTypes.oneOf(["top-inside", "top-outside", "bottom-inside", "bottom-outside"]).isRequired,
    shape: PropTypes.number.isRequired,
    fillColor: PropTypes.string,
    flipY: PropTypes.bool,
    flipX: PropTypes.bool,
}

SectionDivider.defaultProps = {
    shape: 0,
    flipY: false,
    flipX: false,
}
