import PropTypes from "prop-types";

import { Flex } from "@chakra-ui/react";
import { PadlockButton } from "./PadlockButton";

export function DesktopNavigation({ children }) {
    return (
        <Flex
            className="main-navigation"
            sx={{
                alignItems: "center",
            }}
        >
            {children}

            {/* Desktop Login button (Padlock) */}
            <PadlockButton />
        </Flex>
    );
}

DesktopNavigation.propTypes = {
    children: PropTypes.node.isRequired,
};
