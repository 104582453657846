import { SectionDivider } from "@/components/primitives/SectionDivider";
import { contentfulBackground, contentfulTextColor } from "@/theme/contentful-mappings";
import { Box, Container } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useMemo, forwardRef } from "react";

/**
 * Wrapper component that places it's child outside of the content with limited width
 * Only use it if you have to add additional background features that requires the full width
 * (100vw) of a section.
 */
function SectionRoot({ children }) {
    return children;
}

SectionRoot.propTypes = { children: PropTypes.node };

const sectionContainerMaxWidths = {
    md: "container.sm",
    lg: "container.md",
    xl: "container.lg",
    "2xl": "container.xl",
};

/**
 * Wrapper component with responsive and limited width for consistent site structure.
 * Use for section contents.
 */
const SectionContent = forwardRef(({children, sx, ...rest}, ref) =>{
    return (
        <Container
            className="section-content"
            maxW={sectionContainerMaxWidths}
            minW={{
                md: "container.sm",
            }}
            sx={{
                position: "relative",
                zIndex: 2, // Place above section backgrounds/dividers
                ...sx,
            }}
            ref={ref}
            {...rest}
        >
            {children}
        </Container>
    );
})
SectionContent.displayName = "SectionContant";
SectionContent.propTypes = { children: PropTypes.node };

/**
 * Default section wrapper
 */
function Section({ children, sx, sectionStyling, ...rest }) {
    /**
     * Background colors can be provided in 4 ways.. Must be in this priority order
     *      Contentful: Custom divider fill color
     *      Contentful: Custom section background color value
     *      Contentful: Predefined section background color values
     *      Code      : SX code
     * */

    const bgColor = useMemo(() => contentfulBackground(sectionStyling?.backgroundColor), [sectionStyling?.backgroundColor]);
    const textColor = useMemo(() => contentfulTextColor(sectionStyling?.textColor), [sectionStyling?.textColor]);

    const sectionDividers = sectionStyling?.sectionDividers;
    const topSectionDivider = sectionDividers?.find(({ fields }) =>
        fields.position.includes("top")
    )?.fields;
    const bottomSectionDivider = sectionDividers?.find(({ fields }) =>
        fields.position.includes("bottom")
    )?.fields;

    const dividerFillColor = sectionStyling?.customBackgroundColor
        || contentfulBackground(sectionStyling?.backgroundColor)?.backgroundColor
        || sx.backgroundColor;

    return (
        <Box
            as="section"
            sx={{
                position: "relative",
                ...sx,
                // Let custom CMS settings overwrite default styling. Often used in conjuction with sectionDividers
                // Some checks is against undefined as the value 0 would not render the code..
                ...(sectionStyling?.marginTop !== undefined && {
                    marginTop: sectionStyling.marginTop,
                }),
                ...(sectionStyling?.marginBottom !== undefined && {
                    marginBottom: sectionStyling.marginBottom,
                }),
                ...(sectionStyling?.paddingTop !== undefined && {
                    paddingTop: sectionStyling.paddingTop,
                }),
                ...(sectionStyling?.paddingBottom !== undefined && {
                    paddingBottom: sectionStyling.paddingBottom,
                }),
                ...(sectionStyling?.textColor && {
                    ...textColor,
                }),
                ...(sectionStyling?.backgroundColor && {
                    ...bgColor,
                }),
                ...(sectionStyling?.customBackgroundColor && {
                    background: sectionStyling.customBackgroundColor,
                }),
            }}
            {...rest}
        >
            {topSectionDivider && (
                <SectionDivider
                    shape={topSectionDivider.shape}
                    position={topSectionDivider.position}
                    fillColor={topSectionDivider.fillColor || dividerFillColor}
                    flipY={topSectionDivider?.flipY}
                    flipX={topSectionDivider?.flipX}
                />
            )}

            {children}

            {bottomSectionDivider && (
                <SectionDivider
                    shape={bottomSectionDivider.shape}
                    position={bottomSectionDivider.position}
                    fillColor={bottomSectionDivider.fillColor || dividerFillColor}
                    flipY={bottomSectionDivider?.flipY}
                    flipX={bottomSectionDivider?.flipX}
                />
            )}
        </Box>
    );
}

Section.propTypes = {
    children: PropTypes.node,
    sectionStyling: PropTypes.shape({
        marginTop: PropTypes.string,
        marginBottom: PropTypes.string,
        paddingTop: PropTypes.string,
        paddingBottom: PropTypes.string,
        sectionDividers: PropTypes.arrayOf(
            PropTypes.shape({
                fields: PropTypes.shape({
                    shape: PropTypes.number.isRequired,
                    fillColor: PropTypes.string,
                    position: PropTypes.oneOf([
                        "top-inside",
                        "top-outside",
                        "bottom-inside",
                        "bottom-outside",
                    ]).isRequired,
                    flipY: PropTypes.bool,
                    flipX: PropTypes.bool,
                }),
            })
        ),
    }),
};

// Define submodules
Section.Root = SectionRoot;
Section.Content = SectionContent;

export { Section };


