import PropTypes from "prop-types";
import {
    Modal as ChakraModal,
    ModalBody as ChakraModalBody,
    ModalFooter as ChakraModalFooter,
    ModalHeader,
    ModalContent,
    ModalOverlay,
    ModalCloseButton,
} from "@chakra-ui/react";
import { CloseIcon } from "@/components/icons";

function Modal({
    title,
    isOpen,
    onClose,
    children,
    closeable = true,
    ...rest
}) {
    return (
        <ChakraModal
            isCentered
            motionPreset="slideInBottom"
            onClose={onClose}
            isOpen={isOpen}
            closeOnOverlayClick={closeable}
            closeOnEsc={closeable}
            {...rest}
        >
            <ModalOverlay />

            <ModalContent>
                {closeable && (
                    <ModalCloseButton _focus={{ boxShadow: "none", }}>
                        <CloseIcon color={null} sx={{ margin: 1 }} />
                    </ModalCloseButton>
                )}

                {title && <ModalHeader mb={4} >{title}</ModalHeader>}
                {children}
            </ModalContent>
        </ChakraModal>
    );
}

Modal.propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node,
}

Modal.defaultProps = {
    isOpen: false,
}

function ModalBody({
    children,
    ...rest
}) {
    return (
        <ChakraModalBody {...rest}>
            {children}
        </ChakraModalBody>
    )
}

ModalBody.propTypes = {
    children: PropTypes.node,
}

function ModalFooter({
    children,
    ...rest
}) {
    return (
        <ChakraModalFooter {...rest}>
            {children}
        </ChakraModalFooter>
    )
}

ModalFooter.propTypes = {
    children: PropTypes.node,
}

Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export {
    Modal,
}
